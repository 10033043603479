/* global tumarket */

import "./layout.js";
import * as $ from "jquery";
import "bootstrap/js/dist/collapse";
import dep from "../as/controls/as.schedule.js";
import chat from "../chat.js";
import firm from "../firm/firm.js";
import { reviews } from "../firm/reviews.js";
import previews from "../previews/previewsEmbed.js";
import min from "../search/miniature.js";
import rate from "../firm/ratings.js";

//css
import "../../css/modules/firm/firm.css";

import { initEditMenu } from "../editmenu.js";
import { initFirmNotes } from "../firm/addNote.js";

window.addEventListener('load', () => {
	if (tumarket.firm.canEdit) {
		dep.init({
			firmID: tumarket.firm.firmID,
			isEmployee: tumarket.isEmployee || tumarket.firm.isMainFirmAgent,
			isMainFirmAgent: tumarket.firm.isMainFirmAgent,
			username: tumarket.username
		});
	}
	
	chat.init({
		container: "#chatBlock",
		startChatButton: ".tum-chat-toggle",
		role: tumarket.firm.chat.role,
		firmID: tumarket.firm.firmID,
		username: tumarket.username,
		isAuthenticated: tumarket.isAuthenticated,
		canAccessChat: tumarket.firm.chat.enabled,
		chatDisabledMessage: tumarket.firm.chat.chatDisabledMessage,
		callback: () => {
			if (tumarket.firm.chat.show && chat.options.startChatButton) {
				$(chat.options.startChatButton)[0]?.click();
			}
		}
	});
	
	firm.init({
		firmID: tumarket.firm.firmID,
	});
	
	previews.init({
		container: "#mediaBlock",
		disabled: !tumarket.firm.enablePreviews
	});
	
	min.init({ IsAuth: tumarket.isAuthenticated });
	reviews.init({
		userID: tumarket.firm.userID,
		canAccessReview: tumarket.firm.enableReviews,
		isAuthenticated: tumarket.isAuthenticated,
		callback: () => {
			if (tumarket.firm.showReviews) {
				$('#allCommentsBlock').collapse('show');
			}
		}
	});
	
	rate.init({
		isAuthenticated: tumarket.isAuthenticated,
		isRating: tumarket.firm.isRated,
		isFirmAgent: tumarket.firm.isAgent,
		canAccessReview: tumarket.firm.enableReviews,
		pageType: 'firm'
	});
	
	$(document).on('shown.bs.modal', "#asModal", () => {
		if ($(".tum-editRightsDetails").length > 0) {
			$(".tum-editRightsDetails").attr("href", $(".tum-editRightsDetails").attr("href").replace("{role}", tumarket.isEmployee ? "admin" : "client").replace("{firmID}", tumarket.firm.firmID));
		}
	});
	
	var showFirmContactsText = "показать";
	$(".tum-show-firm-contacts span").text(showFirmContactsText);
	
	$(".tum-firm-allOffers").attr("href", "/firm/" + tumarket.firm.firmID + "/offers");
	$(".tum-firm-allOffers").removeClass("d-none");
	
	initEditMenu();
	
	initFirmNotes();
});
