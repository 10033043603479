import { bootstrapAlert } from "../global/alert";
import { closeDialog, showAlert, showDialog } from "../global/dialog";
import { addEventDelegate, toggleClass } from "../global/dom";
import { ajaxSend } from "../global/fetch";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { truncate } from "../global/text";

function showError(txt) {
    let msg = document.querySelector('#asModal .underFieldMsg'),
        block = document.querySelector('#asModal .tum-error-block'),
        fieldset = document.querySelector('#asModal fieldset');

    msg.innerHTML = '<i class="fa fa-exclamation-circle" aria-hidden="true"></i> ' + txt;
    toggleClass(msg, 'errMsg', true);
    toggleClass(fieldset, 'error', true);
    toggleClass(block, 'hintBorder d-none', false);
}

function saveFirmNote(firmID, onSuccess) {
    let note = document.querySelector('#firm-comment').value.trim();
    if (note.length > 512) {
        showError("Превышена максимальная длина заметки");
        document.querySelector('.modal-body textarea').focus();
        return;
    }

    var params = { firmID: firmID, comment: note };
    return ajaxSend({ url: '/FavoriteProducts/UpdateFirmComment', data: params }).then(data => {
        if (data.result) {
            closeDialog();
            bootstrapAlert("Сохранено", { type: 'success' });
            if (onSuccess) onSuccess(note);
        } else {
            showAlert(data.msg || "Возникли ошибки при выполнении операции!", { type: 'danger' });
        }
    });
}

function editFirmNote(firmID, note, onSuccess) {
    let header =
        `<span class="h4 modal-title">Добавить заметку к организации в избранном</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>`

    return loadPrecompiledTemplates(['firm-add-note']).then(() => {
        var tmpl = getPrecompiledTemplate('firm-add-note');
        var htmlOut = tmpl({ comment: note });

        showDialog({
            title: 'Добавить заметку к организации в избранном',
            content: htmlOut,
            header: header,
            submitButtonText: 'Сохранить',
            onSubmit: () => saveFirmNote(firmID, onSuccess)
        });
    });
}

function initFirmNotes(){
    addEventDelegate(document, 'click', '.tum-add-comment', (element, e) => {
        e.preventDefault();
        editFirmNote(element.getAttribute('data-kaid'), element.getAttribute('data-comment'), note => {
            element.querySelector('[data-firm-note]').innerHTML = truncate(note, 5);
            element.setAttribute('data-comment', note);
        });
    });

    addEventDelegate(document, 'click', '.tum-edit-comment .fa-pen', (element, e) => {
        e.stopPropagation();
        var btn = element.closest('.tum-edit-comment');
        editFirmNote(btn.getAttribute('data-kaid'), btn.getAttribute('data-comment'), note => {
            btn.querySelector('.tum-firm-comment').innerHTML = truncate(note, 5);
            btn.setAttribute('data-comment', note);
        });
    });

    addEventDelegate(document, 'keydown', '#asModal textarea.form-control', () => {
        toggleClass('#asModal fieldset', 'error', false);
        toggleClass('#asModal .tum-error-block', 'd-none', true);
    });
}

export { initFirmNotes };