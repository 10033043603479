/**
 * 
 * @param {string} text 
 * @param {number} length 
 * @returns 
 */
function truncate(text, length) {
    if (text.length <= length) return text;
    return text.substring(0, length) + '…';
}

export { truncate };